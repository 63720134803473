h1 {
  font-weight: bold;
  font-size: 32px;
}

h2 {
  font-weight: bold;
}

h3 {
  font-weight: 700;
  font-size: 20px;
}
 
h4 {
  font-weight: 600;
  font-size: 18px;
}

h5 {
  font-weight: bold;
}
@import "./variables";

.p-multiselect:not(.p-disabled):hover,
.p-multiselect:not(.p-disabled):focus-visible,
.p-multiselect:not(.p-disabled):focus-within,
.p-multiselect:not(.p-disabled):focus   {
  border-color: $primaryColor;
  box-shadow: 0 0 0 0.2rem $accentColor;
}

.p-multiselect-items {
  padding: 0!important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: $accentColor;
}
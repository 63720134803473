@import "./variables";

.p-column-filter-buttonbar {
  .p-button {
    background-color: $primaryColor;
    border-color: $primaryColor;
  }
  
  .p-button.p-button-outlined {
    color: $primaryColor;
    background-color: $primaryTextColor !important;
  }
} 
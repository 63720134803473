@import 'styles/overrides';
@import 'styles/nav';
@import 'styles/headings';
@import 'styles/variables';
@import 'styles/utilities';
@import 'styles/select_primeng';
@import 'styles/table_primeng';
@import 'styles/input_primeng';
@import 'styles/button_primeng';
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");


$prefix: 'p-';

@import 'node_modules/primeflex/primeflex.scss';

:root {
  --main-bg-color: #3EB181;
  --disabled-bg: #767680;
  --dark-color: #000; 
  --primary-color:#3EB181 !important;
  --primary-color-text: #fff !important;
  --button-color: #417E71;
  --error: #FF5449;
}

html,
body {
  height: 100vh;
  margin: 0;
  font-family: $font-family-base;
}

.label {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

h1 {
  font-size: 21px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.button-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 15em;
  right: 15em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-color {
  background-color: var(--main-bg-color) !important;
  &:active {
    color: black !important;
  }
}
.dropdown-toggle::after {
  display: none;
  content: none;
}

.p-paginator {
  justify-content: right;
}
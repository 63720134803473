/* This is the overrides of boostrap that is dependent in formio that affects the primeng, primeflex */

.p-dropdown-items {
  margin: 0 !important;
  padding: 0 !important;
}

// .p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
//   border-width: 1px !important;
//   border-style: solid !important;
//   border-color:  #3EB181;
//   color: #3EB181;
// } 

.p-button {
  border-radius: 3px !important;
}

.p-button:focus {
  box-shadow: none;
}

.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #3EB181;
  border: 1px solid;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #3EB181;
  border: 1px solid #3EB181;
}


.p-dropdown:not(.p-disabled):hover {
  border-color: #3EB181;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #3EB181;
}

.p-confirm-dialog-accept {
  margin-left: 5px;
}

.p-confirm-dialog-reject {
  background-color: var(--error);
  border-color: var(--error);
}


/* You can add global styles to this file, and also import other style files */
$font-family-base: "Nunito Sans";
$primary-color: #3EB181;


$successButtonBg: #3EB181 !important;
$successButtonTextColor: #ffffff !important;
$successButtonBorder: 3px solid #3EB181 !important;
$successButtonHoverBg: #16a34a !important;
$successButtonTextHoverColor: $successButtonTextColor !default;
$successButtonHoverBorderColor: #16a34a !important;
$successButtonActiveBg: #15803d !important;
$successButtonTextActiveColor: $successButtonTextColor !important;
$successButtonActiveBorderColor: #15803d !important;
$successButtonFocusShadow: 0 0 0 0.2rem #bbf7d0 !important;

$primaryColor: #3EB181 !important;
$accentColor: #C8E6E0;
$warningColor: #FBC02D;
$primaryTextColor: #fff;

$text-xxs: .625rem; // 10px
$text-base: 1rem; //16pc